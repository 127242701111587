import { cloneDeep, keyBy } from 'lodash-es';
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';

import HawkShare from '~/common/components/organisms/hawk-share.vue';
import { useCommonStore } from '~/common/stores/common.store';
import DMSDocumentCustomFieldForm from '~/dms/components/documents/forms/dms-document-custom-field-form.vue';
import DMSDocumentWeightageForm from '~/dms/components/documents/forms/dms-document-weightage-form.vue';
import DocumentAutonumberConfig from '~/dms/components/documents/forms/document-autonumber-config.vue';

import DocumentRenameForm from '~/dms/components/documents/forms/document-rename-form.vue';
import DocumentTransferOwnerForm from '~/dms/components/documents/forms/document-transfer-ownership-form.vue';
import DocumentVersionsPopup from '~/dms/components/documents/versions/document-versions-popup.vue';
import { useDocumentBulkActions } from '~/dms/composables/document-bulk-actions.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

import NewForm from '~/forms/components/new-form/new-form-modal.vue';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
// stores
import { useDocumentStore } from '../store/document.store';
// components
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';

export function useDocumentActions({ module = '' }) {
  const route = useRoute();
  const router = useRouter();

  const tasks_store = useTasksStore(`${module}_tasks`);
  const forms_store = useFormsStore('forms-dms_forms_store');
  const document_store = useDocumentStore();
  const common_store = useCommonStore();
  const dms_settings_store = useDMSSettingsStore();
  const document_bulk_actions = useDocumentBulkActions();
  const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => field.active));
  const document_statuses = computed(() => dms_settings_store.document_status.filter(document_status => document_status.active));

  const $toast = inject('$toast');
  const $t = inject('$t');

  // SHARE --> start

  const share_modal = useModal({
    component: HawkShare,
    slots: {
      global_access: '',
    },
  });

  async function onShare(data, item) {
    const is_private = data.global_access_level === 'no_access';
    const { type, uid } = item || {};
    try {
      await document_store.crud_documents({
        request: {
          body: {
            [`${type}s`]: {
              update: [
                {
                  uid,
                  ...(type === 'folder' ? { private: is_private } : {}),
                  members: [
                    ...data.users, // members
                    ...data.teams, // teams
                  ],
                },
              ],
            },
          },
        },
      });
      $toast({
        title: $t('File/folder shared successfully'),
        text: $t(`The ${type} have been shared successfully`),
        type: 'success',
        position: 'bottom-right',
      });
    }
    catch (e) {
      logger.error('SHARE: ', e);
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  function openShareModal({ item = {}, prefilled_users = [] }) {
    const teams = [];
    const users = [...prefilled_users];
    const copy_items = cloneDeep(item);
    copy_items.members.forEach((member) => {
      if (common_store.teams_map[member.uid])
        teams.push(member);
      else
        users.push(member);
    });
    return new Promise((resolve, reject) => {
      share_modal.patchOptions({
        slots: {
          global_access: document_bulk_actions.getOrgAssetLabel(copy_items),
        },
        attrs: {
          onClose() {
            share_modal.close();
            reject(new Error('Share cancelled'));
          },
          is_modal: true,
          teams,
          is_global_access_visible: copy_items?.type === 'folder' && !copy_items?.parent && copy_items?.actions?.can_mark_folder_public,
          global_access_level: copy_items?.private ? 'no_access' : 'read',
          members: users,
          global_access_levels: [
            { name: 'no_access', label: $t('No access'), description: $t('Can not access unless shared with them') },
            { name: 'read', label: $t('Can view'), description: $t('Can view the items of the folder and subfolders') },
          ],
          access_levels: [
            { name: 'read', label: $t('Can view'), description: $t('Can view') },
            { name: 'write', label: $t('Can edit'), description: $t('Can view and edit') },
          ],
          get_share_data(data) {
            resolve(data);
            onShare(data, item);
          },
        },
      });
      share_modal.open();
    });
  }

  // RENAME --> start
  const rename_modal = useModal({
    component: DocumentRenameForm,
    slots: {
      title_text: $t('Rename'),
    },
  });

  function itemRename({ item = {} }) {
    rename_modal.patchOptions({
      attrs: {
        footer_buttons: [
          { type: 'outlined', text: 'Cancel', class: 'mr-2' },
          { color: 'primary', text: 'Save' },
        ],
        onClose() {
          rename_modal.close();
        },
        item,
      },
    });
    rename_modal.open();
  }
  // RENAME --> end

  // VERSION POPUP --> start
  const version_modal = useModal({
    component: DocumentVersionsPopup,
  });

  function openVersions({ item = {} }) {
    version_modal.patchOptions({
      attrs: {
        onClose() {
          version_modal.close();
        },
        file: item,
        versions: item.versions,
      },
    });
    version_modal.open();
  }
  // VERSION POPUP --> end

  // TRANSFER OWNERSHIP --> start
  const transfer_ownership_modal = useModal({
    component: DocumentTransferOwnerForm,
    slots: {
      title_text: $t('Transfer Ownership'),
    },
  });

  function openTransferOwnership({ item = {} }) {
    transfer_ownership_modal.patchOptions({
      attrs: {
        footer_buttons: [
          { type: 'outlined', text: 'Cancel', class: 'mr-2' },
          { color: 'primary', text: 'Transfer' },
        ],
        onClose() {
          transfer_ownership_modal.close();
        },
        item,
      },
    });
    transfer_ownership_modal.open();
  }
  // TRANSFER OWNERSHIP --> end

  // CREATE TASK --> start
  const task_modal = useModal({
    component: TaskForm,
  });

  function openCreateTask({ item = {}, source = '' }) {
    task_modal.patchOptions({
      attrs: {
        onClose() {
          task_modal.close();
        },
        async on_submit(data) {
          const payload = {
            ...data,
            target_element: item.element,
          };
          const new_task = await tasks_store.create_tasks({ tasks: [payload] }, { method: 'Direct' });
          const task = btoa(JSON.stringify({
            id: Object.keys(new_task)[0],
            store_key: `${source}_tasks`,
          }));
          router.push({ ...route, query: { task } });
        },
      },
    });
    task_modal.open();
  }
  // CREATE TASK --> end

  // CREATE FORM --> start
  const form_modal = useModal({
    component: NewForm,
  });

  function openCreateForm({ item = {} }) {
    form_modal.patchOptions({
      attrs: {
        onClose() {
          form_modal.close();
        },
        async on_submit(data) {
          data.forms.add[0].target_element = item.element;

          await forms_store?.create_form({
            body: data,
          });
          form_modal.close();
        },
      },
    });
    form_modal.open();
  }
  // CREATE FORM --> end

  // AUTONUMBERING --> start
  const autonumber_config_modal = useModal({
    component: DocumentAutonumberConfig,
  });

  function openAutoNumbering({ item = {} }) {
    const sub_header = [...item.ancestors.map(ancestor => ancestor.name), item.name].join(' -> ');
    autonumber_config_modal.patchOptions({
      attrs: {
        header: $t('Auto numbering schema'),
        folder_data: item,
        sub_header,
        onClose() {
          autonumber_config_modal.close();
        },
        async save(data) {
          let config_data = null;
          if (item.config_uid)
            config_data = await document_store.add_or_update_autonum_configuration({ request_type: 'patch', autonumber: data, config_uid: item.config_uid });
          else
            config_data = await document_store.add_or_update_autonum_configuration({ request_type: 'post', autonumber: data });

          config_data && await document_store.crud_documents({
            request: {
              body: {
                [`${item.type}s`]: {
                  update: [
                    {
                      uid: item.uid,
                      config_uid: config_data.uid,
                      update_existing_files: data.update_existing_files,
                    },
                  ],
                },
              },
            },
          });
        },
      },
    });
    autonumber_config_modal.open();
  }
  // AUTONUMBERING --> end

  // Edit

  const custom_field_modal = useModal({
    component: DMSDocumentCustomFieldForm,
  });

  async function updateCustomFields(item, data) {
    const { type, uid } = item || {};

    const field_value_payload = [];

    for (const uid in data.field_values)
      field_value_payload.push({ field: uid, value: data.field_values[uid] });

    try {
      await document_store.crud_documents({
        request: {
          body: {
            [`${type}s`]: {
              update: [
                {
                  uid,
                  name: item.name === data?.name ? undefined : data?.name,
                  number: data?.number,
                  field_values: field_value_payload,
                  status: data.document_status,
                  tags: data.tags,
                  category: data.category,
                },
              ],
            },
          },
        },
      });
      $toast({
        title: 'File custom fields updated successfully',
        text: `The ${type} custom fields have been updated successfully`,
        type: 'success',
        position: 'bottom-right',
      });
    }
    catch (e) {
      logger.error('Custom Field: ', e);
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  function openCustomFieldModal(item) {
    const field_values_map = keyBy(item?.field_values, 'field');

    custom_field_modal.patchOptions({
      attrs: {
        header: $t('Update custom fields'),
        custom_fields,
        document: item,
        has_active_transmittal: !!item?.active_transmittal?.uid,
        document_status: item.status,
        document_status_options: document_statuses,
        field_values_map,
        onClose() {
          custom_field_modal.close();
        },
        async save(data) {
          await updateCustomFields(item, data);
        },
      },
    });
    custom_field_modal.open();
  }

  const assign_weightage_modal = useModal({
    component: DMSDocumentWeightageForm,
  });

  function openAssignWeightage(item) {
    assign_weightage_modal.patchOptions({
      attrs: {
        header: 'Assign weightage',
        document: item,
        onClose() {
          assign_weightage_modal.close();
        },
        async confirm(body) {
          await document_store.crud_documents({
            request: {
              body,
            },
          });
          assign_weightage_modal.close();
        },
      },
    });
    assign_weightage_modal.open();
  }

  return {
    itemRename,
    openTransferOwnership,
    openCreateTask,
    openCreateForm,
    openVersions,
    openAutoNumbering,
    openCustomFieldModal,
    openAssignWeightage,
    openShareModal,
  };
}
